<template>
  <a-spin :spinning="state.loadings.page_open">
    <sdPageHeader :title="$t.orders" />
    <Main>
      <a-row
        type="flex"
        justify="center"
        :gutter="12"
        align="top"
      >
        <a-col
          v-if="getPrintList().length > 0"
          :md="16"
          :xs="24"
        >
          <a-button
            type="dashed"
            style="position: absolute;top: -40px;right: 0"
            @click="runPrintList()"
          >
            <template #icon>
              <PrinterOutlined />
            </template>
            {{ $t.batch_printing }}
          </a-button>
        </a-col>
        <a-col
          :md="16"
          :xs="24"
        >
          <ProgressBarStyle>
            <a-progress
              :stroke-color="{
                from: '#108ee9',
                to: '#87d068',
              }"
              :percent="getGeneralLoaderPercent()"
              status="active"
              :format="percent => `${percent}%`"
              :style="{ marginBottom: '15px' }"
              title="İlerleme Durumu"
            />
          </ProgressBarStyle>
        </a-col>
      </a-row>


      <a-row
        type="flex"
        justify="center"
        :gutter="12"
        align="top"
      >
        <a-col
          :md="16"
          :xs="24"
        >
          <a-card
            v-for="item in state.shipment_results"
            :key="item.code"
            :title="item.code"
          >
            <template
              v-if="item.state === 'success' && item.barcode_link && item.barcode_link.length > 0"
              #extra
            >
              <a-button
                type="dashed"
                @click="singlePrint(item.barcode_link)"
              >
                <template #icon>
                  <PrinterOutlined />
                </template>
                {{ $t.print }}
              </a-button>
            </template>
            <a-alert
              v-if="item.state==='info'"
              :message="item.description"
              type="info"
              show-icon
            />
            <a-alert
              v-else-if="item.state==='success'"
              :message="item.description"
              type="success"
              show-icon
            />
            <a-alert
              v-else
              :message="item.description"
              type="error"
              show-icon
            />
            <a-collapse v-if="item.state !== 'info'">
              <a-collapse-panel :header="$t.cargoInformation">
                <pre>{{ item.debug_info ? item.debug_info : '' }}</pre>
              </a-collapse-panel>
            </a-collapse>
            <span
              v-if="item.state !== 'info' && item.show_suggestion"
              :style="{ width: '100%' }"
            >
              <a-space>
                <span v-if="item.suggestion_type === 'shipping_company'">
                  <a-select
                    v-model:value="item.shipping_company_id"
                    :default-value="$t.selectCargo"
                    style="width: 160px"
                  >
                    <a-select-option
                      v-for="shippingCompany in item.shipping_companies"
                      :key="shippingCompany.id"
                      :value="shippingCompany.id"
                    >
                      {{ shippingCompany.title }}
                    </a-select-option>
                  </a-select>
                  <a-button
                    type="primary"
                    @click="setCargoAgain(item)"
                  >
                    Kargo Gönder
                  </a-button>
                </span>
                <span v-else-if="item.suggestion_type === 'location'">
                  <a-select
                    v-model:value="item.city_code"
                    :default-value="$t.selectCity"
                    style="width: 160px"
                    :loading="state.loadings.location_fetching"
                    @change="changeCitySelect(item)"
                  >
                    <a-select-option
                      v-for="cityItem in item.cities"
                      :key="cityItem.code"
                      :value="cityItem.code"
                    >
                      {{ cityItem.name }}
                    </a-select-option>
                  </a-select>
                  <span
                    v-if="item.location_selector_level === 'two'"
                  >
                    <a-select
                      v-model:value="item.town_code"
                      :default-value="$t.selectDistrict"
                      style="width: 160px"
                      :loading="state.loadings.location_fetching"
                    >
                      <a-select-option
                        v-for="townItem in item.towns"
                        :key="townItem.code"
                        :value="townItem.code"
                      >
                        {{ townItem.name }}
                      </a-select-option>
                    </a-select>
                    <a-button
                      type="primary"
                      @click="setCargoAgain(item)"
                    >
                      Kargo Gönder
                    </a-button>
                  </span>

                  <span
                    v-else-if="item.location_selector_level === 'three'"
                  >
                    <a-select
                      v-model:value="item.town_code"
                      :default-value="$t.selectDistrict"
                      style="width: 160px"
                      :loading="state.loadings.location_fetching"
                      @change="changeTownSelect(item)"
                    >
                      <a-select-option
                        v-for="townItem in item.towns"
                        :key="townItem.code"
                        :value="townItem.code"
                      >
                        {{ townItem.name }}
                      </a-select-option>
                    </a-select>
                    <a-select
                      v-model:value="item.district_code"
                      :default-value="$t.selectTown"
                      style="width: 160px"
                    >
                      <a-select-option
                        v-for="districtItem in item.districts"
                        :key="districtItem.code"
                        :value="districtItem.code"
                      >
                        {{ districtItem.name }}
                      </a-select-option>
                    </a-select>
                    <a-button
                      type="primary"
                      @click="setCargoAgain(item)"
                    >
                      {{ $t.sendCargo }}
                    </a-button>
                  </span>

                </span>
              </a-space>
            </span>
          </a-card>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>

import { Main } from '../styled'
import { ProgressBarStyle } from '../uiElements/ui-elements-styled'
import { PrinterOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive } from 'vue'
import restMethods from '@/utility/restMethods'
import { t } from '@/utility/language'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { useTopMenu } from '@/composables/useTopMenu'

export default defineComponent({
  name: 'Order',
  components: {
    Main,
    ProgressBarStyle,
    PrinterOutlined,
  },
  setup() {

    const route = useRoute()

    const { reload } = useTopMenu()

    const state = reactive({
      loadings: {
        page_open: false,
        location_fetching: false,
      },
      shipment_print_url: '',

      cargo_keys: [],
      shipment_results: {},
    })

    onMounted(() => {

      state.loadings.page_open = true

      restMethods.postForWebApp('/create-shipment-open').then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          state.shipment_print_url = data.shipment_print_url

          state.cargo_keys = route.query.orders.split(',')

          for (const cargoKey of state.cargo_keys) {
            state.shipment_results[cargoKey] = {
              code: cargoKey,
              state: 'info',
              description: t.loading,
              barcode_link: '',
              debug_info: {},
              show_suggestion: false,
            }
          }

          getOrderState()

        }

        state.loadings.page_open = false

      })

    })

    const getGeneralLoaderPercent = () => {
      const processedCount = getProcessedCount()
      const totalCount = state.cargo_keys.length

      return (processedCount * 100) / totalCount
    }

    const getProcessedCount = () => {
      let count = 0
      for (const result of Object.values(state.shipment_results)) {
        if (result.state !== 'info') {
          count++
        }
      }
      return count
    }

    const setCargoAgain = (item) => {

      switch (item.suggestion_type) {

        case 'shipping_company':

          if (item.shipping_company_id === '') {
            message.error(t.selectCargoCompany)
            return false
          }

          createShipment(item.code, {
            shipping_company_id: item.shipping_company_id,
          })

          break

        case 'location':

          if (item.city_code === '' || item.city_code === undefined || item.city_code === null) {
            message.error(t.selectCity)
            return false
          }

          if (item.town_code === '' || item.town_code === undefined || item.town_code === null) {
            message.error(t.selectDistrict)
            return false
          }

          if (item.location_selector_level === 'three') {

            if (item.district_code === '' || item.district_code === undefined || item.district_code === null) {
              message.error(t.selectTown)
              return false
            }

          }

          createShipment(item.code, {
            city_code: item.city_code,
            town_code: item.town_code,
            district_code: item.district_code,
          })

          break

      }

    }

    const changeCitySelect = (item) => {
      state.loadings.location_fetching = true
      restMethods.postForWebApp('/get-town-via-city', {
        city_code: item.city_code,
        location_selector_level: item.location_selector_level,
      }).then(response => {
        if ( ! response.hasError()) {

          item.town_code = ''
          item.towns = []
          if (item.location_selector_level === 'three') {
            item.district_code = ''
            item.districts = []
          }

          item.towns = response.getData().items

        }
        state.loadings.location_fetching = false
      })
    }

    const changeTownSelect = (item) => {

      state.loadings.location_fetching = true
      restMethods.postForWebApp('/get-district-via-city', {
        city_code: item.city_code,
        town_code: item.town_code,
        location_selector_level: item.location_selector_level,
      }).then(response => {
        if ( ! response.hasError()) {

          item.district_code = ''
          item.districts = []

          item.districts = response.getData().items

        }
        state.loadings.location_fetching = false
      })
    }

    const singlePrint = (value) => {
      window.open(value + ('/' + localStorage.getItem('jwtToken')), '_blank')
    }

    const runPrintList = () => {
      const printList = getPrintList()
      if (printList.length < 1) {
        message.error(t.atLeastOneRecordMustBeSelected)
      } else {
        window.open(state.shipment_print_url + '/' + printList.join(',') + '/' + localStorage.getItem('jwtToken'), '_blank')
      }
    }

    const getPrintList = () => {
      const items = []
      for (const shipmentResult of Object.values(state.shipment_results)) {
        if (shipmentResult.state === 'success' && shipmentResult.barcode_link && shipmentResult.barcode_link.length > 0) {
          items.push(shipmentResult.code)
        }
      }
      return items
    }

    const createShipment = (cargoKey, data = {}) => {

      state.shipment_results[cargoKey].state = 'info'
      state.shipment_results[cargoKey].description = t.loading

      return restMethods.postForWebApp('/create-shipment/' + cargoKey, data, { show_error: false }).then(response => {
        if ( ! response.hasError()) {

          state.shipment_results[cargoKey] = response.getData()

        } else {

          state.shipment_results[cargoKey] = {
            state: 'error',
            description: response.getErrorMessage(),
            debug_info: {},
            show_suggestion: false,
          }

        }

      })
    }

    const getOrderState = () => {

      const processedCount = getProcessedCount()

      if (state.cargo_keys.length !== processedCount) {

        const activeCargoKey = state.cargo_keys[processedCount]

        createShipment(activeCargoKey).then(() => {

          getOrderState()

        })

      } else {

        // üst header'daki bilgileri güncelleyelim
        reload()

      }

    }

    return {
      state,

      getGeneralLoaderPercent,

      setCargoAgain,
      changeCitySelect,
      changeTownSelect,
      singlePrint,
      runPrintList,
      getPrintList,
    }

  },
})

</script>
